<template>
  <div class="coupon-scope" :class="{skeleton: !state.loaded}">
    <div class="list" v-if="couponScope === 'A'">
      <div class="search">
        <div class="subject">
          <span>조건 검색</span>
          <button class="btn" @click="load()">
            <span>검색</span>
            <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="input">
          <select class="form-control bg-white" :id="`${component.name}ScopeSearchFundingContest`" v-model="state.args.contest.key">
            <option value="contestName">대회 이름</option>
            <option value="contestSeq">대회 번호</option>
          </select>
        </div>
        <div class="input">
          <input class="form-control" v-model="state.args.contest.value" :placeholder="`${state.args.contest.key === 'contestName' ? '대회 이름을' : '대회 번호를' } 입력해주세요`" @keyup.enter="load()">
        </div>
      </div>
      <div class="top">
        <div class="left">
          <span>총 {{ state.scopes.length }}개</span>
          <span class="font-xs color-anchor pl-2" v-if="state.updateIds.length">{{ state.updateIds.length }}개 선택됨</span>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" :id="`${component.name}OnlyAssigned`" :checked="state.args.assignCoupon === 'Y'" @change="loadOnlyAssigned($event)">
          <label :for="`${component.name}OnlyAssigned`" class="form-check-label">지정된 대회만 보기</label>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
          <tr>
            <th>
              <input type="checkbox" @click="checkAll($event, 'contest')" :checked="state.updateIds.length && state.updateIds.length === state.contests.length">
            </th>
            <th>지정 여부</th>
            <th>펀딩 대회 이름</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="c in state.scopes" :key="c.contestSeq">
            <td><input type="checkbox" :value="c.contestSeq" @click="check($event)" :checked="state.updateIds.join('').includes(c.contestSeq)"></td>
            <td>
              <span class="badge badge-primary" v-if="c.assignYn === 'Y'">지정</span>
              <span class="badge badge-secondary" v-else>미지정</span>
            </td>
            <td>
              <span>{{ c.contestName }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="list" v-else-if="couponScope === 'B'">
      <div class="search">
        <div class="subject">
          <span>조건 검색</span>
          <button class="btn" @click="load()">
            <span>검색</span>
            <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="input form-floating select">
          <select class="form-control" :id="`${component.name}ScopeSearchFundingContest`" v-model="state.args.project.contestSeq" required>
            <option value="">펀딩 대회를 선택해주세요</option>
            <option :value="p.contestSeq" v-for="p in state.contests" :key="p.contestSeq">{{ p.contestName }}</option>
          </select>
          <label :for="`${component.name}ScopeSearchFundingContest`">펀딩 대회</label>
        </div>
        <div class="input form-floating">
          <input type="text" class="form-control" :id="`${component.name}ProjectSeq`" v-model="state.args.project.projectSeq" required>
          <label :for="`${component.name}ProjectSeq`">프로젝트 번호</label>
        </div>
        <div class="input form-floating">
          <input type="text" class="form-control" :id="`${component.name}ProjectName`" v-model="state.args.project.projectName" required>
          <label :for="`${component.name}ProjectName`">프로젝트 명</label>
        </div>
        <div class="input form-floating">
          <input type="text" class="form-control" :id="`${component.name}MemberName`" v-model="state.args.project.memberName" required>
          <label :for="`${component.name}MemberName`">진행자 명</label>
        </div>
        <div class="input flex-column">
          <span class="title">프로젝트 진행 상태</span>
          <div class="group">
            <div class="form-check">
              <input class="form-check-input" type="radio" :name="`${component.name}ProjectState`" :id="`${component.name}ProjectStateAll`" value="" v-model="state.args.project.projectState" checked>
              <label class="form-check-label" :for="`${component.name}ProjectStateAll`">전체</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" :name="`${component.name}ProjectState`" :id="`${component.name}ProjectStateSoon`" value="soon" v-model="state.args.project.projectState">
              <label class="form-check-label" :for="`${component.name}ProjectStateSoon`">준비 중</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" :name="`${component.name}ProjectState`" :id="`${component.name}ProjectStateIng`" value="ing" v-model="state.args.project.projectState">
              <label class="form-check-label" :for="`${component.name}ProjectStateIng`">진행 중</label>
            </div>
          </div>
        </div>
      </div>
      <div class="top">
        <div class="left">
          <span>총 {{ state.scopes.length }}개</span>
          <span class="font-xs color-anchor pl-2" v-if="state.updateIds.length">{{ state.updateIds.length }}개 선택됨</span>
        </div>
        <div class="form-check">
          <input type="checkbox" class="form-check-input" :id="`${component.name}OnlyAssigned`" @change="loadOnlyAssigned($event)">
          <label :for="`${component.name}OnlyAssigned`" class="form-check-label">지정된 프로젝트만 보기</label>
        </div>
      </div>
      <div class="accordion thin-scrollbar" :id="`${component.name}ScopeAccordion`" v-if="state.scopes.length">
        <div class="wrapper header">
          <div class="parent">
            <div class="row">
              <div class="col-1">
                <label class="m-0 w-100">
                  <input type="checkbox" class="form-check" @click.stop="checkAll($event, 'project')" :checked="state.updateIds.length && state.updateIds.length === state.scopes.length">
                </label>
              </div>
              <div class="col-1">
                <span>지정 여부</span>
              </div>
              <div class="col-6">
                <span>프로젝트 제목</span>
              </div>
              <div class="col-4">
                <span>펀딩 기간</span>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper" v-for="s in state.scopes" :key="s.projectSeq">
          <div class="parent" :id="`${component.name}ScopeParent${s.projectSeq}`">
            <div class="row pointer" data-toggle="collapse" :data-target="`#${component.name}ScopeChild${s.projectSeq}`" :aria-controls="`${component.name}ScopeChild${s.projectSeq}`">
              <div class="col-1">
                <label @click.stop class="m-0 w-100">
                  <input type="checkbox" class="form-check" @change="check($event)" :value="s.projectSeq" :checked="state.updateIds.join('').includes(s.projectSeq)">
                </label>
              </div>
              <div class="col-1">
                <span class="badge badge-primary" v-if="s.assignYn === 'Y'">지정</span>
                <span class="badge badge-secondary" v-else>미지정</span>
              </div>
              <div class="col-6">
                <a class="color-anchor">{{ s.projectName }}</a>
              </div>
              <div class="col-4">
                <span>{{ $lib.getDateFormat(s.investStartDate, "yyyy-MM-dd") }} ~ {{ $lib.getDateFormat(s.investEndDate, "yyyy-MM-dd") }}</span>
              </div>
            </div>
          </div>
          <div :id="`${component.name}ScopeChild${s.projectSeq}`" class="collapse child" :aria-labelledby="`${component.name}ScopeParent${s.projectSeq}`" :data-parent="`#${component.name}ScopeAccordion`">
            <div class="content">
              <div class="group">
                <span class="title">프로젝트 번호</span>
                <span class="item">{{ s.projectSeq }}</span>
              </div>
              <div class="group">
                <span class="title">진행자명</span>
                <span class="item">{{ s.memberName }}</span>
              </div>
              <div class="group">
                <span class="title">펀딩 기간</span>
                <span class="item">{{ $lib.getDateFormat(s.investStartDate, "yyyy-MM-dd") }} ~ {{ $lib.getDateFormat(s.investEndDate, "yyyy-MM-dd") }}</span>
              </div>
              <div class="group">
                <span class="title">쿠폰 참여 회원 수</span>
                <span class="item">{{ $lib.getNumberFormat(s.usedMemberCount) }} 명</span>
              </div>
              <div class="group">
                <span class="title">포인트 참여(예약 금액)</span>
                <span class="item">{{ $lib.getNumberFormat(s.usedCouponSum) }} 원</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="btn btn-bordered-purple" @click="assignScope()">지정</button>
      <button class="btn danger" @click="unAssignScope()">지정 해제</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageAdminCouponCouponScope";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    couponId: String,
    couponScope: "A" | "B" | "C",
    save: Function,
  },
  setup(props) {
    const component = new Component(() => {
      loadContests();
      (props.couponId && props.couponScope) && load();
    });

    const state = reactive({
      loaded: false,
      scopes: [],
      updateIds: [],
      contests: [],
      args: {
        contest: {
          key: "contestName",
          value: "",
          assignCoupon: "",
        },
        project: {
          projectName: "",
          projectSeq: null,
          projectState: "",
          contestSeq: "",
          memberName: "",
          assignCoupon: "",
        },
      }
    });

    const load = () => {
      const args = {};
      const category = props.couponScope === "A" ? "contest" : "project";

      if (category === "contest") {
        state.args.contest.value && (args[state.args.contest.key] = state.args.contest.value);
        args.assignCoupon = state.args.contest.assignCoupon;
      } else {
        for (let i in state.args[category]) {
          state.args[category][i] && (args[i] = state.args[category][i]);
        }
      }

      args.category = category;

      state.loaded = false;
      http.get(`/api/admin/coupons/${props.couponId}/scopes`, args).then(({data}) => {
        state.loaded = true;
        state.scopes = data.body;
      });
    };

    const loadContests = () => {
      http.get(`/api/admin/coupons/contests`).then(({data}) => {
        state.contests = data.body;
      });
    };

    const check = (e) => {
      e.target.checked === true
          ? state.updateIds.push(e.target.value)
          : state.updateIds.splice(state.updateIds.findIndex(s => s.toString() === e.target.value.toString()), 1);
    };

    const loadOnlyAssigned = (e) => {
      const category = props.couponScope === "A" ? "contest" : "project";

      if (e.target.checked) {
        state.args[category].assignCoupon = "Y";
      } else {
        state.args[category].assignCoupon = "";
      }

      load();
    };

    const checkAll = (e) => {
      if (e.target.checked === true) {
        state.updateIds = props.couponScope === "A"
            ? state.scopes.map(c => c.contestSeq)
            : state.scopes.map(r => r.projectSeq);
      } else {
        state.updateIds = [];
      }
    };

    const assignScope = () => {
      if (!state.updateIds.length) {
        return store.commit("지정 범위를 체크해주세요");
      }

      const args = {
        sequences: state.updateIds,
        category: props.couponScope === "A" ? "contest" : "project"
      };

      typeof props.save === "function" && props.save();

      http.post(`/api/admin/coupons/${props.couponId}/scopes`, args).then(() => {
        store.commit("setSwingMessage", "쿠폰 적용 범위가 설정되었습니다.");
        load();
      });
    };

    const unAssignScope = () => {
      if (!state.updateIds.length) {
        return store.commit("setSwingMessage", `해제할 ${props.couponScope === "A" ? "대회" : "프로젝트"}를 체크해주세요`);
      }

      const args = {
        sequences: state.updateIds.join(","),
        category: props.couponScope === "A" ? "contest" : "project"
      };

      typeof props.save === "function" && props.save();

      http.delete(`/api/admin/coupons/${props.couponId}/scopes`, args).then(() => {
        store.commit("setSwingMessage", `선택한 ${props.couponScope === "A" ? "대회" : "프로젝트"}의 지정이 해제되었습니다..`);
        load();
      });
    };

    watch(() => props.couponScope, (next, prev) => {
      if (next !== prev) {
        state.updateIds = [];
        state.scopes = [];
        load();
      }
    });

    return {component, state, load, check, checkAll, assignScope, unAssignScope, loadOnlyAssigned};
  }
});
</script>

<style lang="scss" scoped>
.coupon-scope {
  .list {
    > .top {
      margin-top: $px16;
      display: flex;
      justify-content: space-between;
      font-size: $px14;
    }

    .table-responsive {
      margin-top: $px16;

      .table {
        border: 0 !important;

        thead {
          background-color: $colorBackground;
        }

        tr {
          border: 0;

          th, td {
            border: 0;
            padding: $px8;
          }
        }
      }
    }

    .accordion {
      margin-top: $px16;
    }
  }

  &.skeleton {
    .list {
      .search {
        .subject {
          span, .btn {
            @include skeleton;
          }
        }

        .input {
          input, label, .form-control, span {
            @include skeleton;
          }

          input[type="radio"] {
            display: none;
          }
        }
      }

      > .top {
        span {
          @include skeleton;
        }
      }

      .accordion > .wrapper {
        .parent .row > div {
          span, label, a {
            @include skeleton;
          }

          input[type="checkbox"] {
            display: none;
          }
        }
      }
    }

    > .actions {
      .btn {
        @include skeleton;
      }
    }
  }
}
</style>