<template>
  <div class="image-upload">
    <label class="coupon" title="쿠폰 이미지 등록하기">
      <span class="img" v-if="state.file" :style="{backgroundImage: `url(${state.file.url})`}"></span>
      <i class="fa fa-plus" v-else></i>
      <input type="file" :accept="$definitions.limits.fileExtensions.imgStr" @change="setFile($event)">
    </label>
    <div class="right">
      <div class="texts">
        <span class="desc">가로 이미지를 권장합니다. (권장 가로 사이즈: 680px)</span>
        <b class="title" v-if="state.file">{{ state.file.originName }}</b>
        <b class="title" v-else>(N/A)</b>
      </div>
      <div class="actions" v-if="state.file">
        <div class="display">
          <div class="subject">
            <span class="title">상세 페이지 미노출</span>
            <div class="dropdown">
              <i class="fa fa-question-circle" type="button" :id="`${component.name}CouponImageDisplay`" data-toggle="dropdown" aria-haspopup="true"></i>
              <div class="dropdown-menu" :aria-labelledby="`${component.name}CouponImageDisplay`">
                <span>단, 해당 기능이 비활성화여도 쿠폰 사용 기간이 종료된 경우 이미지가 노출되지 않습니다.</span>
              </div>
            </div>
          </div>
          <label class="switch">
            <input type="checkbox" :checked="state.file.displayFlag === 'N'" @change="updateImageDisplay($event)">
            <span class="slider round"></span>
          </label>
        </div>
        <button class="btn danger" @click="remove()">삭제</button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import Phone from "@/components/Phone.vue";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageAdminCouponCouponImageUpload";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Phone},
  mixins: [mixin],
  props: {
    couponId: String
  },
  setup(props) {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      file: null,
    });

    const load = () => {
      http.get(`/api/admin/coupons/${props.couponId}/images`).then(({data}) => {
        state.file = data.body;
      });
    };

    const getCouponImageUrl = () => {
      return window.URL.createObjectURL(state.file);
    };

    const setFile = (e) => {
      if (!store.getters.isAllowedExtension(e.target, "image")) {
        return;
      }

      store.commit("confirm", {
        message: `${e.target.files[0].name} 이미지를 등록하시겠습니까?`,
        subMessage: "쿠폰 사용 기간동안 적용 범위 프로젝트 상세 페이지에 노출됩니다.",
        async allow() {
          if (state.file) {
            await remove();
          }

          const formData = new FormData;
          formData.append("image", e.target.files[0]);

          await http.post(`/api/admin/coupons/${props.couponId}/images`, formData);
          e.target.value = "";
          store.commit("이미지가 등록되었습니다.");
          load();
        }
      });
    };

    const updateImageDisplay = (e) => {
      const args = {displayFlag: "Y"};

      e.target.checked && (args.displayFlag = "N");

      http.put(`/api/admin/coupons/${props.couponId}/images`, args);
    };

    const remove = async () => {
      if (!state.file) {
        return;
      }

      await http.delete(`/api/admin/coupons/${props.couponId}/images`);
      state.file = null;
    };

    return {component, state, getCouponImageUrl, setFile, remove, updateImageDisplay};
  }
});
</script>

<style lang="scss" scoped>
.image-upload {
  display: flex;
  gap: $px16;

  .coupon {
    cursor: pointer;
    width: $px200;
    height: $px150;
    background: $colorBackground;
    border-radius: $px16;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    margin: 0;

    input[type="file"] {
      width: 0;
      height: 0;
      display: none;
      font-size: 0;
    }

    > .fa-plus {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: $px30;
      color: $colorSecondary;
    }

    > .img {
      background-size: auto 100%;
      background-position: center center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  > .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .texts {
      display: flex;
      flex-direction: column;
      gap: $px16;

      .desc {
        font-size: $px13;
        color: $colorSecondary;
      }

      .title {
        font-size: $px18;
      }
    }

    .actions {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .display {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: $px14;

        .subject {
          padding-right: $px24;

          .dropdown {
            padding-left: $px4;

            .dropdown-menu {
              font-size: $px14;
              padding: $px16;
            }
          }
        }

        .switch {
          position: relative;
          display: inline-block;
          width: $px50;
          height: $px21;
          margin-bottom: 0;

          > input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
              background-color: $colorPurple;

              &::before {
                transform: translate($px29, -50%);
              }
            }

            &:focus + .slider {
              box-shadow: 0 0 $px1 $colorBoxShadow;
            }
          }

          > .slider {
            cursor: pointer;
            background-color: #ccc;
            display: inline-block;
            position: relative;
            width: 100%;
            height: 100%;
            transition: .4s;

            &.round {
              border-radius: $px50;

              &::before {
                border-radius: 50%;
              }
            }

            &::before {
              position: absolute;
              content: "";
              height: $px13;
              width: $px13;
              left: $px4;
              top: 50%;
              transform: translateY(-50%);
              background-color: #fff;
              transition: 0.18s;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .coupon {
      width: $px150;
      height: $px112;
    }
  }
}
</style>