<template>
  <div class="coupon common-style">
    <div class="row" v-if="state.mode !== 'create'">
      <div class="col-6 col-lg-3">
        <div class="title">
          <b>쿠폰수</b>
          <div class="dropdown">
            <i class="fa fa-question-circle" type="button" :id="`${component.name}CouponCount`" data-toggle="dropdown" aria-haspopup="true"></i>
            <div class="dropdown-menu" :aria-labelledby="`${component.name}CouponCount`">
              <span>생성: 쿠폰발급관리에서 생성한 쿠폰번호 수</span>
              <span>전환: 회원이 전환한 쿠폰번호 수</span>
              <span>잔여: 잔여 쿠폰번호 수</span>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="wrapper">
            <span class="title">생성</span>
            <span class="item">{{ $lib.getNumberFormat(state.coupon.totalCouponCount) }}</span>
          </div>
          <div class="wrapper">
            <span class="title">전환</span>
            <span class="item">{{ $lib.getNumberFormat(state.coupon.assignCouponCount) }}</span>
          </div>
          <div class="wrapper">
            <span class="title">잔여</span>
            <span class="item">{{ $lib.getNumberFormat(state.coupon.totalCouponCount - state.coupon.assignCouponCount) }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 col-lg-3">
        <div class="title">
          <b>금액</b>
          <div class="dropdown">
            <i class="fa fa-question-circle" type="button" :id="`${component.name}Amount`" data-toggle="dropdown" aria-haspopup="true"></i>
            <div class="dropdown-menu" :aria-labelledby="`${component.name}Amount`">
              <span>생성: 포인트 금액 합</span>
              <span>전환: 회원이 전환한 포인트 금액 합</span>
              <span>잔여: 사용한 포인트 금액 합</span>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="wrapper">
            <span class="title">생성</span>
            <span class="item">{{ $lib.getNumberFormat(state.coupon.totalCouponSum) }}</span>
          </div>
          <div class="wrapper">
            <span class="title">전환</span>
            <span class="item">{{ $lib.getNumberFormat(state.coupon.assignCouponSum) }}</span>
          </div>
          <div class="wrapper">
            <span class="title">사용</span>
            <span class="item">{{ $lib.getNumberFormat(state.coupon.assignCouponSum - state.coupon.restCouponSum) }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 col-lg-3">
        <div class="title">
          <b>프로젝트 수</b>
          <div class="dropdown">
            <i class="fa fa-question-circle" type="button" :id="`${component.name}ProjectCount`" data-toggle="dropdown" aria-haspopup="true"></i>
            <div class="dropdown-menu" :aria-labelledby="`${component.name}ProjectCount`">
              <span>적용대상: 쿠폰의 적용대상 프로젝트 수</span>
              <span>사용: 사용한 프로젝트 수</span>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="wrapper">
            <span class="title">적용대상</span>
            <span class="item">{{ state.coupon.scopeProjectCount }}</span>
          </div>
          <div class="wrapper">
            <span class="title">사용</span>
            <span class="item">{{ state.coupon.usedProjectCount }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 col-lg-3">
        <div class="title">
          <b>회원수</b>
          <div class="dropdown">
            <i class="fa fa-question-circle" type="button" :id="`${component.name}MemberCount`" data-toggle="dropdown" aria-haspopup="true"></i>
            <div class="dropdown-menu" :aria-labelledby="`${component.name}MemberCount`">
              <span>전환: 쿠폰을 입력하여 포인트로 전환한 회원 수</span>
              <span>참여(예약): 참여(예약)한 회원 수</span>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="wrapper">
            <span class="title">생성</span>
            <span class="item">{{ state.coupon.assignCouponMemberCount }}</span>
          </div>
          <div class="wrapper">
            <span class="title">참여(예약)</span>
            <span class="item">{{ state.coupon.usedMemberCount }}</span>
          </div>
        </div>
      </div>
    </div>
    <template v-for="s in computedSections">
      <div class="wrapper" :key="s.name" v-if="s.visible">
        <div class="guide">
          <b class="title">{{ s.title }}</b>
          <span class="desc">{{ s.desc }}</span>
        </div>
        <div class="section" v-if="s.name === 'setting'" :class="{skeleton: !state.loaded}">
          <div class="form-floating form-floating-count">
            <input type="text" class="form-control" :maxlength="couponNameMaxLeng" v-model="state.coupon.couponName" :id="`${component.name}CouponName`" required>
            <label :for="`${component.name}CouponName`">
              <span>쿠폰명</span>
            </label>
            <span class="count">{{ !state.coupon.couponName.length ? 0 : state.coupon.couponName.length }} / {{ couponNameMaxLeng }}</span>
          </div>
          <div class="form-floating">
            <input type="text" class="form-control" v-model="state.coupon.couponDesc" :id="`${component.name}CouponDesc`" required>
            <label :for="`${component.name}CouponDesc`">메모</label>
          </div>
          <div class="input">
            <div class="subject">
              <span>적용 범위 설정</span>
            </div>
            <div class="group">
              <div class="form-check">
                <input class="form-check-input" type="radio" :name="`${component.name}CouponScope`" :id="`${component.name}CouponScopeA`" value="B" v-model="state.coupon.couponScope">
                <label class="form-check-label" :for="`${component.name}CouponScopeA`">지정 프로젝트</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" :name="`${component.name}CouponScope`" :id="`${component.name}CouponScopeB`" value="A" v-model="state.coupon.couponScope">
                <label class="form-check-label" :for="`${component.name}CouponScopeB`">지정 대회</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" :name="`${component.name}CouponScope`" :id="`${component.name}CouponScopeC`" value="C" v-model="state.coupon.couponScope">
                <label class="form-check-label" :for="`${component.name}CouponScopeC`">모든 프로젝트</label>
              </div>
            </div>
          </div>
          <div class="input">
            <div class="subject">
              <span>사용 기간</span>
            </div>
            <div class="group">
              <Date componentNameSuffix="S" :id="`${component.name}UsableStartDate`" placeholder="시작일" inputClass="border-focus-purple" :value.sync="state.coupon.usableStartDate" format="yyyy-MM-dd"/>
              <span>-</span>
              <Date componentNameSuffix="E" :id="`${component.name}UsableEndDate`" placeholder="종료일" inputClass="border-focus-purple" :value.sync="state.coupon.usableEndDate" format="yyyy-MM-dd"/>
            </div>
          </div>
          <div class="input">
            <div class="subject">
              <span class="title">중복 혀용</span>
              <span class="desc">쿠폰을 중복하여 발급 받을 수 있습니다.</span>
            </div>
            <div class="group">
              <div class="form-check">
                <input class="form-check-input" type="radio" :name="`${component.name}MultiUseYn`" :id="`${component.name}MultiUseY`" value="Y" v-model="state.coupon.multiUseYn">
                <label class="form-check-label" :for="`${component.name}MultiUseY`">중복 발급 가능</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" :name="`${component.name}MultiUseYn`" :id="`${component.name}MultiUseN`" value="N" v-model="state.coupon.multiUseYn">
                <label class="form-check-label" :for="`${component.name}MultiUseN`">중복 발급 불가</label>
              </div>
            </div>
          </div>
          <div class="input">
            <div class="subject">
              <span class="title">쿠폰 활성화</span>
              <span class="desc">사용자가 쿠폰을 발급받거나 사용할 수 있도록 합니다.</span>
            </div>
            <div class="group">
              <div class="form-check">
                <input class="form-check-input" type="radio" :name="`${component.name}UseAbleYn`" :id="`${component.name}UseAbleY`" value="Y" v-model="state.coupon.useYn">
                <label class="form-check-label" :for="`${component.name}UseAbleY`">활성화</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" :name="`${component.name}UseAbleYn`" :id="`${component.name}UseAbleN`" v-model="state.coupon.useYn" value="N">
                <label class="form-check-label" :for="`${component.name}UseAbleN`">비활성화</label>
              </div>
            </div>
          </div>
          <div class="input">
            <div class="subject flex-row justify-content-between">
              <div class="texts">
                <span>쿠폰 지정 발급</span>
                <span class="desc d-block">수동으로 사용자에게 쿠폰을 발급합니다.</span>
              </div>
              <button class="btn btn-secondary" v-if="state.coupon.couponId && state.coupon.autoGenerateYn === 'Y'" @click="copy(`https://www.ohmycompany.com/point/${state.coupon.couponId}/generate`)">쿠폰 발급 URL 생성</button>
            </div>
            <div class="group">
              <div class="form-check">
                <input class="form-check-input" type="radio" :name="`${component.name}AutoGenerateYn`" :id="`${component.name}AutoGenerateN`" value="N" v-model="state.coupon.autoGenerateYn">
                <label class="form-check-label" :for="`${component.name}AutoGenerateN`">수동 발급</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" :name="`${component.name}fAutoGenerateYn`" :id="`${component.name}AutoGenerateY`" value="Y" v-model="state.coupon.autoGenerateYn" checked>
                <label class="form-check-label" :for="`${component.name}AutoGenerateY`">자동 발급</label>
              </div>
            </div>
          </div>
          <div class="actions">
            <button class="btn btn-purple" @click="save()">저장</button>
          </div>
        </div>
        <div class="section" v-else-if="s.name === 'scope'">
          <CouponScope :couponId="state.coupon.couponId" :couponScope="state.coupon.couponScope" :save="save"/>
        </div>
        <div class="section" v-else-if="s.name === 'release'">
          <CouponPool :couponId="state.coupon.couponId" :couponName="state.coupon.couponName"/>
        </div>
        <div class="section" v-else-if="s.name === 'imageUpload'">
          <CouponImageUpload :couponId="state.coupon.couponId"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Date from "@/components/Date.vue";
import router from "@/scripts/router";
import http from "@/scripts/http";
import store from "@/scripts/store";
import $lib from "../../../scripts/lib";
import lib from "../../../scripts/lib";
import CouponScope from "@/pages/admin/coupon/CouponScope.vue";
import CouponPool from "@/pages/admin/coupon/CouponPool.vue";
import CouponImageUpload from "@/pages/admin/coupon/CouponImageUpload.vue";
import Phone from "@/components/Phone.vue";

function Component(initialize) {
  this.name = "pageAdminCouponCoupon";
  this.initialize = initialize;
}

export default defineComponent({
  computed: {
    lib() {
      return lib;
    },
    $lib() {
      return $lib;
    }
  },
  components: {Phone, CouponImageUpload, CouponPool, CouponScope, Date},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      if (router.app.$route.params.couponId === "create") {
        state.loaded = true;
        state.mode = "create";
      } else {
        state.coupon.couponId = router.app.$route.params.couponId;
        load();
      }
    });

    const state = reactive({
      mode: "",
      loaded: false,
      coupon: {
        couponId: "",
        couponName: "",
        couponDesc: "",
        couponScope: "B",
        usableStartDate: "",
        usableEndDate: "",
        useYn: "Y",
        multiUseYn: "N",
        autoGenerateYn: "Y",
      },
    });

    const computedSections = computed(() => {
      return [{
        name: "setting",
        title: "옵션 설정",
        desc: "",
        visible: true,
      }, {
        name: "scope",
        title: "적용 범위",
        desc: "해당 쿠폰을 사용할 수 있는 프로젝트 범위를 선택합니다.",
        visible: state.loaded && state.coupon.couponId && ["A", "B"].includes(state.coupon.couponScope),
      }, {
        name: "release",
        title: "발행",
        desc: "실제 사용자에게 지급될 쿠폰을 발행합니다.",
        visible: state.loaded && state.coupon.couponId,
      }, {
        name: "imageUpload",
        title: "이미지 등록",
        desc: "해당 쿠폰이 적용되는 모든 프로젝트 상세 페이지 최상단에 해당 이미지가 노출됩니다.",
        visible: state.loaded && state.coupon.couponId && state.coupon.couponScope !== "C" && state.coupon.autoGenerateYn !== "N",
      }];
    });

    const couponNameMaxLeng = 22;

    const load = () => {
      if (state.mode === "create") {
        return;
      }

      state.loaded = false;
      http.get(`/api/admin/coupons/${state.coupon.couponId}`).then(({data}) => {
        state.loaded = true;
        state.coupon = data.body;
      });
    };

    const warn = (message, id) => {
      store.commit("setSwingMessage", message);
      return document.getElementById(id)?.focus();
    };

    const save = async () => {
      const args = {
        couponName: "",
        couponDesc: "",
        usableStartDate: "",
        usableEndDate: "",
        couponScope: "",
        useYn: "",
        autoGenerateYn: "",
        multiUseYn: "",
      };

      if (!state.coupon.couponName) {
        warn("쿠폰명을 입력해주세요", `${component.name}CouponName`);
        return false;
      } else if (!state.coupon.couponName?.trim().length > couponNameMaxLeng) {
        warn(`쿠폰명을 ${couponNameMaxLeng}자 이내로 입력해주세요`, `${component.name}CouponName`);
        return false;
      } else if (!state.coupon.usableStartDate) {
        warn("사용 시작일을 설정해주세요", `${component.name}UsableStartDate`);
        return false;
      } else if (!state.coupon.usableEndDate) {
        warn("사용 종료일을 설정해주세요", `${component.name}UsableEndDate`);
        return false;
      } else if (state.coupon.usableEndDate < state.coupon.usableStartDate) {
        warn("사용 종료일을 시작일 이후로 설정해주세요", `${component.name}UsableEndDate`);
        return false;
      }

      for (let i in args) {
        args[i] = state.coupon[i];
      }

      state.coupon.couponId
          ? await http.put(`/api/admin/coupons/${state.coupon.couponId}`, args)
          : await http.post("/api/admin/coupons", args).then(({data}) => {
            if (state.mode === "create") {
              state.mode = "edit";
              state.coupon.couponId = data.body;
              return router.replace(`/new-admin/coupons/${state.coupon.couponId}`);
            }
          });

      load();
    };

    const copy = (value) => {
      if (!state.coupon.couponId) {
        return;
      }

      lib.copyToClipboard(value);
      store.commit("setSwingMessage", "클립보드에 복사하였습니다.");
    };

    return {
      component
      , state
      , couponNameMaxLeng
      , computedSections
      , save
      , copy
    };
  }
});
</script>

<style lang="scss" scoped>
.common-style::v-deep {
  .form-floating {
    position: relative;

    input[type="text"], .form-control {
      padding: $px24 $px10 $px8;
      height: auto;
    }

    > label {
      top: 50%;
      left: $px8;
      position: absolute;
      padding: 0 $px5 0 $px5;
      font-size: $px14;
      transition: 0.1s;
      transform-origin: bottom left;
      transform: translateY(-50%);
    }

    select ~ label {
      top: $px4;
      transform: translate($px1, 0) scale(0.80);
      opacity: .8;
    }

    > .form-control {
      &:focus ~ label {
        top: $px4;
        transform: translate($px1, 0) scale(0.80);
        opacity: .8;
        color: $colorPurple;
      }

      &:valid ~ label {
        top: $px4;
        transform: translate($px1, 0) scale(0.80);
        opacity: .8;
      }
    }
  }

  .input {
    font-size: $px14;

    > .subject {
      display: flex;
      flex-direction: column;
      margin-bottom: $px8;

      .desc {
        color: #999;
        font-size: $px13;
      }
    }

    > .group {
      display: flex;
      align-items: center;
      gap: $px16;
    }
  }

  .list {
    .search {
      background-color: $colorBackground;
      border-radius: $px4;
      padding: $px16;
      display: flex;
      flex-wrap: wrap;
      gap: $px16;

      > .subject {
        font-size: $px16;
        font-weight: 500;
        width: 100%;
        display: flex;
        justify-content: space-between;

        > .btn {
          font-size: $px14;
          padding: $px6 $px16;
          background-color: $colorSecondary;
          color: #fff;
          border-radius: $px100;

          &:hover {
            background-color: $colorSecondaryActive;
          }
        }
      }

      > .input {
        display: flex;
        gap: $px6;

        .title {
          display: block;
        }

        &.select {
          flex-grow: 1;
          background: #fff;
        }
      }
    }

    .accordion {
      max-height: $px430;
      overflow: auto;
      margin-left: $px-5;
      margin-right: $px-5;
      position: relative;

      > .wrapper {
        border-radius: $px4;

        .parent {
          font-size: $px14;
          padding: $px8;

          > .row {
            margin-left: 0;
            margin-right: 0;
            align-items: center;

            > div {
              padding-left: $px5;
              padding-right: $px5;

              > .btn {
                padding: $px4 $px12;
                font-size: $px12;
              }
            }
          }
        }

        .child {
          background-color: $colorBackground;
          border-radius: $px4;

          > .content {
            font-size: $px13;
            padding: $px8 $px16;

            .group {
              display: flex;
              align-items: center;

              > .title {
                width: 20%;
                flex-shrink: 0;
              }
            }
          }
        }

        &.header {
          background-color: $colorBackground;
          font-size: $px13;
          font-weight: 500;
          position: sticky;
          top: 0;
          z-index: 1;
        }

        &:hover {
          background-color: $colorBackground;
        }
      }
    }
  }

  .actions {
    text-align: center;
    margin-top: $px16;

    .btn {
      &.danger {
        border: $colorDanger solid $px1;
        color: $colorDanger;

        &:hover {
          background-color: $colorDanger;
          color: #fff;
        }
      }
    }
  }
}

.coupon {
  display: flex;
  flex-direction: column;
  gap: $px70;

  > .row {
    > div {
      font-size: $px14;

      .title {
        b {
          font-size: $px12;
        }

        .dropdown {
          i {
            font-size: $px14;
            padding: $px8;
          }

          .dropdown-menu {
            padding: $px16;
            font-size: $px12;

            > span {
              display: block;
              white-space: nowrap;
            }
          }
        }
      }

      .content {
        > .wrapper {
          display: flex;
          gap: $px16;

          .title {
            width: 30%;
            color: #999;
          }
        }
      }
    }
  }

  > .wrapper {
    display: flex;
    gap: $px30;

    > .guide {
      width: 20%;
      flex-shrink: 0;

      > .title {
        font-size: $px18;
        margin-bottom: $px8;
        display: block;
      }

      > .desc {
        font-size: $px14;
        color: $colorSecondary;
        white-space: pre-line;
      }
    }

    > .section {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: $px32;

      .form-floating {
        .count {
          position: absolute;
          top: 50%;
          right: $px16;
          transform: translateY(-50%);
          font-size: $px12;
          color: #999;
        }

        &.form-floating-count {
          .form-control {
            padding-right: $px56;
          }
        }
      }

      > .input {
        > .subject {
          .texts {
            flex-shrink: 0;
          }

          > .btn {
            font-size: $px12;
            margin-left: $px16;
            padding: $px6 $px16;
            word-break: keep-all;
          }
        }
      }

      &.skeleton {
        > .form-floating {
          input, label {
            @include skeleton;
          }
        }

        > .input {
          .btn, span, label {
            @include skeleton;
          }

          .group {
            .form-check {
              @include skeleton;

              input[type="radio"] {
                display: none;
              }
            }
          }
        }

        > .actions {
          .btn {
            @include skeleton;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    gap: $px30;

    > .row {
      > div {
        padding-bottom: $px16;
      }
    }

    > .wrapper {
      flex-direction: column;
      gap: $px16;

      > .guide {
        width: 100%;
      }
    }
  }
}
</style>