<template>
  <div class="coupon-pool" :class="{skeleton: !state.loaded}">
    <div class="list">
      <div class="search">
        <div class="subject">
          <span>조건 검색</span>
          <button class="btn" @click="load()">
            <span>검색</span>
            <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="input form-floating">
          <input type="text" class="form-control" :id="`${component.name}ReleaseProjectName`" v-model="state.args.projectName" required>
          <label :for="`${component.name}ReleaseProjectName`">프로젝트 명</label>
        </div>
        <div class="input form-floating">
          <input type="text" class="form-control" :id="`${component.name}ReleaseProjectSeq`" v-model="state.args.projectSeq" required>
          <label :for="`${component.name}ReleaseProjectSeq`">프로젝트 번호</label>
        </div>
        <div class="input flex-column">
          <span class="title">쿠폰 상태</span>
          <div class="group">
            <div class="form-check">
              <input class="form-check-input" type="radio" :name="`${component.name}UsableCoupon`" :id="`${component.name}UsableCouponAll`" v-model="state.args.usableCoupon" value="" checked>
              <label class="form-check-label" :for="`${component.name}UsableCouponAll`">전체</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" :name="`${component.name}UsableCoupon`" :id="`${component.name}UsableCouponUnUsed`" v-model="state.args.usableCoupon" value="Y">
              <label class="form-check-label" :for="`${component.name}UsableCouponUnUsed`">미사용</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" :name="`${component.name}UsableCoupon`" :id="`${component.name}UsableCouponRegistered`" v-model="state.args.usableCoupon" value="N">
              <label class="form-check-label" :for="`${component.name}UsableCouponRegistered`">등록됨</label>
            </div>
          </div>
        </div>
      </div>
      <template v-if="state.releases.length">
        <div class="top">
          <div class="left">
            <span class="pr-2">총 {{ state.releases.length }}개</span>
            <span class="font-xs color-anchor" v-if="state.updateIds.length">{{ state.updateIds.length }}개 선택됨</span>
          </div>
          <div class="right">
            <a @click="downloadExcel('coupons')" class="btn btn-secondary">
              <i class="fa fa-download"></i>
              <span>쿠폰 목록 엑셀</span>
            </a>
            <a @click="downloadExcel('usages')" class="btn btn-secondary">
              <i class="fa fa-download"></i>
              <span>사용 현황 엑셀</span>
            </a>
            <label class="pointer">
              <span class="font-sm color-anchor">일괄 수동 발급</span>
              <input type="file" :accept="$definitions.limits.fileExtensions.allStr" @change="setFile($event)"/>
            </label>
          </div>
        </div>
        <div class="accordion thin-scrollbar" :id="`${component.name}ReleaseAccordion`">
          <div class="wrapper header">
            <div class="parent">
              <div class="title row">
                <div class="col-1">
                  <label class="m-0 w-100">
                    <input type="checkbox" class="form-check" @click.stop="checkAll($event)" :checked="state.updateIds.length && state.updateIds.length === state.releases.map(r => r.memberId ? '' : r.couponNo).filter(r => !!r).length">
                  </label>
                </div>
                <div class="col-2">
                  <span>쿠폰 번호</span>
                </div>
                <div class="col-2">
                  <span>포인트</span>
                </div>
                <div class="col-3">
                  <span>이메일</span>
                </div>
                <div class="col-3">
                  <span>전환가능일</span>
                </div>
                <div class="col-1">
                  <span>상세</span>
                </div>
              </div>
            </div>
          </div>
          <div class="wrapper" v-for="r in state.releases" :key="r.couponNo">
            <div class="parent" :id="`${component.name}ReleaseParent${r.couponNo}`">
              <div class="row pointer" data-toggle="collapse" :data-target="`#${component.name}ReleaseChild${r.couponNo}`" :aria-controls="`${component.name}ReleaseChild${r.couponNo}`">
                <div class="col-1">
                  <label class="m-0 w-100" v-if="!r.memberId">
                    <input type="checkbox" class="form-check" @click.stop="check($event, r.couponNo)" :checked="state.updateIds.includes(r.couponNo)">
                  </label>
                </div>
                <div class="col-2">
                  <span>{{ r.couponNo }}</span>
                </div>
                <div class="col-2">
                  <span>{{ $lib.getNumberFormat(r.couponPoint) }}</span>
                </div>
                <div class="col-3 ellipsis">
                  <span>{{ r.memberId || "(N/A)" }}</span>
                </div>
                <div class="col-3 ellipsis">
                  <span>{{ $lib.getDateFormat(r.availableStartDate, "yyyy-MM-dd") }} ~ {{ $lib.getDateFormat(r.availableEndDate, "yyyy-MM-dd") }}</span>
                </div>
                <div class="col-1">
                  <button class="btn btn-secondary" @click.stop="openCouponDetail([r.couponNo])">상세</button>
                </div>
              </div>
            </div>
            <div :id="`${component.name}ReleaseChild${r.couponNo}`" class="collapse child" :aria-labelledby="`${component.name}ReleaseParent${r.couponNo}`" :data-parent="`#${component.name}ReleaseAccordion`">
              <div class="content">
                <div class="group">
                  <span class="title">회원등록여부</span>
                  <span class="item">{{ r.assignDate ? `등록됨(${r.assignDate})` : "(N/A)" }}</span>
                </div>
                <div class="group">
                  <span class="title">쿠폰 기간</span>
                  <span class="item">{{ `${$lib.getDateFormat(r.usableStartDate, "yyyy-MM-dd")} ~ ${$lib.getDateFormat(r.usableEndDate, "yyyy-MM-dd")}` }}</span>
                </div>
                <div class="group" v-if="r.memberId">
                  <span class="title">쿠폰 사용 현황</span>
                  <span class="item" @click="openCouponPoolLog(r.couponNo)"><span class="color-anchor pointer">사용 현황 확인하기</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <button class="btn btn-bordered-purple" @click="edit()">수정</button>
          <button class="btn danger" @click="remove()">삭제</button>
        </div>
      </template>
      <div class="add">
        <button class="btn btn-purple" @click="openCouponDetail()">+ 쿠폰 발행</button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import store from "@/scripts/store";
import Phone from "@/components/Phone.vue";

function Component(initialize) {
  this.name = "pageAdminCouponCouponPool";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Phone},
  mixins: [mixin],
  props: {
    couponId: String,
    couponName: String,
    contests: Array,
  },
  setup(props) {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      loaded: false,
      releases: [],
      updateIds: [],
      args: {
        projectName: "",
        projectSeq: null,
        usableCoupon: "",
      },
      file: null
    });

    const load = () => {
      const args = {};

      for (let i in state.args) {
        state.args[i] && (args[i] = state.args[i]);
      }

      state.loaded = false;
      http.get(`/api/admin/coupons/${props.couponId}/pools`, args).then(({data}) => {
        state.loaded = true;
        state.releases = data.body;
      });
    };

    const check = (e, seq) => {
      e.target.checked === true ? state.updateIds.push(seq) : state.updateIds.splice(state.updateIds.findIndex(s => s === seq), 1);
    };

    const checkAll = (e) => {
      if (e.target.checked === true) {
        state.updateIds = state.releases.map(r => r.memberId ? null : r.couponNo).filter(r => !!r);
      } else {
        state.updateIds = [];
      }
    };

    const openCouponDetail = (couponNos, mode) => {
      store.commit("openModal", {
        name: "CouponDetail",
        routerReplace: true,
        params: {
          couponName: props.couponName,
          couponId: props.couponId,
          couponNos,
          mode
        },
        callback: `${component.name}.load`
      });
    };
    const openCouponPoolLog = (couponNo) => {
      store.commit("openModal", {
        name: "CouponPoolLog",
        routerReplace: true,
        params: {
          couponNo
        }
      });
    };

    const edit = () => {
      if (!state.updateIds.length) {
        return store.commit("setSwingMessage", "수정할 쿠폰들을 선택해주세요");
      }

      openCouponDetail(state.updateIds, "multiple");
    };

    const remove = () => {
      if (!state.updateIds.length) {
        return store.commit("setSwingMessage", "삭제할 쿠폰들을 선택해주세요");
      }

      store.commit("confirm", {
        message: "해당 쿠폰들을 삭제하시겠습니까?",
        subMessage: "발급 완료된 쿠폰은 삭제할 수 없습니다.",
        allow() {
          const params = {
            couponNos: state.updateIds.filter(u => state.releases.map(r => r.memberId ? "" : r.couponNo).includes(u)).join(",")
          };

          http.delete(`/api/admin/coupons/pools`, params).then(() => {
            state.updateIds = [];
            store.commit("setSwingMessage", "삭제되었습니다.");
            load();
          });
        }
      });
    };

    const setFile = (e) => {
      if (!store.getters.isAllowedExtension(e.target, "all")) {
        return;
      }

      store.commit("confirm", {
        message: `${e.target.files[0].name} 파일로 쿠폰을 발급하시겠습니까?`,
        subMessage: "사용자에게 발급된 쿠폰은 수정 및 삭제가 불가합니다.",
        allow() {
          const formData = new FormData();
          formData.append("file", e.target.files[0]);

          http.post(`/api/admin/coupons/${props.couponId}/template/upload`, formData).then(({data}) => {
            e.target.value = "";
            load();
            window.alert(`쿠폰이 발급되었습니다. 시도: ${data.body.try}개 성공: ${data.body.apply}`);
          });
        },
        disallow() {
          e.target.value = "";
        }
      });
    };

    const downloadExcel = (type) => {
      const url = type === "coupons" ? `/api/admin/coupons/${props.couponId}/template/download` : `/api/admin/coupons/${props.couponId}/usages/download`;

      http.get(url, null, {responseType: "blob"}).then((res) => {
        const contentDisposition = res.headers["content-disposition"];
        const fileName = contentDisposition
            ? contentDisposition.split("filename=")[1].replace(/['"]/g, "")
            : `${props.couponName + new window.Date().getTime()}.xlsx`;
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const newTab = window.open();
        newTab.opener = null;

        const link = newTab.document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`);
        newTab.document.body.appendChild(link);
        link.click();
        newTab.document.body.removeChild(link);
        newTab.close();

        window.URL.revokeObjectURL(url);
      });
    };

    return {
      component
      , state
      , load
      , check
      , checkAll
      , openCouponDetail
      , openCouponPoolLog
      , edit
      , remove
      , setFile
      , downloadExcel
    };
  }
});
</script>

<style lang="scss" scoped>
.coupon-pool {
  > .list {
    > .add {
      text-align: right;
      margin-top: $px16;

      .btn {
        border-radius: $px100;
      }
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: $px16;

      .right {
        display: flex;
        gap: $px16;

        .btn {
          padding: $px4 $px16;
          font-size: $px12;
          margin: 0;
        }

        label {
          margin: 0;
        }

        input[type="file"] {
          display: none;
          font-size: 0;
          width: 0;
          height: 0;
        }
      }
    }

    .accordion {
      margin-top: $px16;

      > .wrapper {
        .parent {
          > .row > div {
            white-space: nowrap;
          }
        }
      }
    }
  }

  &.skeleton {
    .list {
      .search {
        .subject {
          span, .btn {
            @include skeleton;
          }
        }

        .input {
          input, label, .form-control, span {
            @include skeleton;
          }

          input[type="radio"] {
            display: none;
          }
        }
      }

      > .top {
        span, .btn {
          @include skeleton;
        }
      }

      .accordion > .wrapper {
        .parent .row > div {
          span, label, a, .btn {
            @include skeleton;
          }

          input[type="checkbox"] {
            display: none;
          }
        }
      }

      > .actions, .add {
        .btn {
          @include skeleton;
        }
      }
    }
  }

  @media (max-width: 767px) {
    > .list {
      .top {
        flex-direction: column;
        align-items: start;
        gap: $px16;
      }

      .accordion {
        > .wrapper {
          &.header {
            .parent .row > div {
              font-size: $px12;
            }
          }
        }
      }
    }
  }
}
</style>